<template>
  <b-card-code title="Ajouter Maiére">
    <b-card-text>
      <span>Veuillez saisir les coordonnées de Matière </span>
    </b-card-text>

    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="4">
            <b-form-group
              label="Année Univ"
              label-for="Année Univ"
            >
              <validation-provider
                #default="{ errors }"
                name="Année Univ"
                rules="required"
              >
                <b-form-input
                  v-model="subject.academic_year"
                  :state="errors.length > 0 ? false : null"
                  disabled
                  placeholder="Année Univ"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Parcours"
              label-for="Parcours"
            >
              <validation-provider
                #default="{ errors }"
                name="state"
                rules="required"
              >
                <v-select
                  v-model="subject.parcour"
                  label="title"
                  :clearable="false"
                  :options="parcours"
                  placeholder="Parcours"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Niveau"
              label-for="Niveau"
            >
              <validation-provider
                #default="{ errors }"
                name="Niveau"
                rules="required"
              >
                <b-form-input
                  v-model="subject.level"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Niveau"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Unité"
              label-for="Unité"
            >
              <validation-provider
                #default="{ errors }"
                name="Unité"
              >
                <v-select
                  v-model="subject.unity"
                  label="label"
                  :clearable="false"
                  :options="teachingUnits"
                  placeholder="unite"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Libellé matière"
              label-for="Libellé matière"
            >
              <validation-provider
                #default="{ errors }"
                name="Libellé matière"
                rules="required"
              >
                <b-form-input
                  v-model="subject.label"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Libellé matière"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Libellé en Arabe"
              label-for="Libellé en Arabe"
            >
              <validation-provider
                #default="{ errors }"
                name="Libellé en Arabe"
                rules="required"
              >
                <b-form-input
                  v-model="subject.label_arabe"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Libellé en Arabe"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Coef"
              label-for="Coef"
            >
              <validation-provider
                #default="{ errors }"
                name="Coef"
                rules="required"
              >
                <b-form-input
                  v-model="subject.coefficient"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Coef"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Crédit"
              label-for="Crédit"
            >
              <validation-provider
                #default="{ errors }"
                name="Crédit"
                rules="required"
              >
                <b-form-input
                  v-model="subject.credit"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Crédit"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Volume Horaire"
              label-for="Volume Horaire"
            >
              <validation-provider
                #default="{ errors }"
                name="Volume Horaire"
                rules="required"
              >
                <b-form-input
                  v-model="subject.hourly_volume"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Volume Horaire"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Charge/Semaine"
              label-for="Charge/Semaine"
            >
              <validation-provider
                #default="{ errors }"
                name="Charge/Semaine"
                rules="required"
              >
                <b-form-input
                  v-model="subject.load_week"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Charge/Semaine"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Code Matière"
              label-for="Code Matière"
            >
              <validation-provider
                #default="{ errors }"
                name="Code Matière"
                rules="required"
              >
                <b-form-input
                  v-model="subject.code"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Code Matière"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
              label="% Assiduité"
              label-for="% Assiduité"
            >
              <validation-provider
                #default="{ errors }"
                name="% Assiduité"
                rules="required"
              >
                <b-form-input
                  v-model="subject.assiduity"
                  :state="errors.length > 0 ? false : null"
                  placeholder="% Assiduité"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="% DS"
              label-for="% DS"
            >
              <validation-provider
                #default="{ errors }"
                name="% DS"
                rules="required"
              >
                <b-form-input
                  v-model="subject.ds"
                  :state="errors.length > 0 ? false : null"
                  placeholder="% DS"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="% Examen"
              label-for="% Examen"
            >
              <validation-provider
                #default="{ errors }"
                name="% Examen"
                rules="required"
              >
                <b-form-input
                  v-model="subject.exam"
                  :state="errors.length > 0 ? false : null"
                  placeholder="% Examen"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="% TP"
              label-for="% TP"
            >
              <validation-provider
                #default="{ errors }"
                name="% TP"
                rules="required"
              >
                <b-form-input
                  v-model="subject.tp"
                  :state="errors.length > 0 ? false : null"
                  placeholder="% TP"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="% Mini Projet"
              label-for="% Mini Projet"
            >
              <validation-provider
                #default="{ errors }"
                name="% Mini Projet"
                rules="required"
              >
                <b-form-input
                  v-model="subject.mini_project"
                  :state="errors.length > 0 ? false : null"
                  placeholder="% Mini Projet"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Nombre d'absences autorisées"
              label-for="Nombre d'absences autorisées"
            >
              <validation-provider
                #default="{ errors }"
                name="Nombre d'absences autorisées"
                rules="required"
              >
                <b-form-input
                  v-model="subject.number_authorized_absences"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Nombre d'absences autorisées"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Type de DS"
              label-for="Type de DS"
            >
              <validation-provider
                #default="{ errors }"
                name="state"
                rules="required"
              >
                <v-select
                  v-model="subject.supervised_ds_mode"
                  label="title"
                  :clearable="false"
                  :options="supervised_ds_mode"
                  placeholder="Type de DS"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Type de Examen"
              label-for="Type de Examen"
            >
              <validation-provider
                #default="{ errors }"
                name="state"
                rules="required"
              >
                <v-select
                  v-model="subject.supervised_exam_mode"
                  label="title"
                  :clearable="false"
                  :options="supervised_exam_mode"
                  placeholder="Type de Examen"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Mode de Examen"
              label-for="Mode de Examen"
            >
              <validation-provider
                #default="{ errors }"
                name="state"
                rules="required"
              >
                <v-select
                  v-model="subject.exam_mode"
                  label="title"
                  :clearable="false"
                  :options="exam_mode"
                  placeholder="Mode de Examen"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <h5>Etat de Matière</h5>
              <b-form-checkbox
                v-model="subject.is_active"
                name="check-button"
                switch
                inline
                @click="subject.is_active = true"
              >
                Actif
              </b-form-checkbox>
            </b-form-group>

          </b-col>
          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click.prevent="addSubjects"
            >
              Ajouter
            </b-button>
            <b-button
              variant="outline-secondary"
              type="reset"
            >
              Réinitialiser
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import storeAuth from '@/store/store'

import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText /*  */,
  BFormCheckbox,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    /* BFormTextarea, */
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BFormCheckbox,
    BButton,
    vSelect,
  },
  data() {
    return {
      password: '',
      subject: {
        label_arabe: '',
        parcour: {},
        unity: '',
        number_authorized_absences: '',
        supervised_exam_mode: '',
        supervised_ds_mode: '',
        exam_mode: '',
        tp: '',
        mini_project: '',
        exam: '',
        ds: '',
        assiduity: '',
        coefficient: '',
        credit: '',
        code: '',
        load_week: '',
        hourly_volume: '',
        level: '',
        label: '',
        academic_year_id: '',
        is_active: false,

      },
      parcours: [],
      teachingUnits: [],
      supervised_ds_mode: ['DSPratique', 'DSTheorique'],
      supervised_exam_mode: ['ExamPratique', 'ExamTheorique'],
      exam_mode: ['Mixte', 'contrôle Continue', 'PFE'],
      required,

    }
  },
  created() {
    this.getParcours()
    this.getTeachingUnits()
    this.subject.academic_year = storeAuth.state.currentAcademicYear.academic_year
    this.subject.academic_year_id = storeAuth.state.currentAcademicYear.id
  },
  methods: {
    async getParcours() {
      const response = await axios.get('/api/parcours/')
      this.parcours = response.data
    },
    async getTeachingUnits() {
      this.load = 'true'
      const response = await axios.get('/api/teaching-units/')
      this.teachingUnits = response.data
      this.load = 'false'
    },

    async addSubjects() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            await axios
              .post(
                '/api/subjects/create/',
                {
                  academic_year: this.subject.academic_year_id,
                  supervised_exam_mode: this.subject.supervised_exam_mode,
                  supervised_ds_mode: this.subject.supervised_ds_mode,
                  label: this.subject.label,
                  level: this.subject.level,
                  hourly_volume: this.subject.hourly_volume,
                  load_week: this.subject.load_week,
                  code: this.subject.code,
                  credit: this.subject.credit,
                  coefficient: this.subject.coefficient,
                  assiduity: this.subject.assiduity,
                  ds: this.subject.ds,
                  exam: this.subject.exam,
                  parcour: this.subject.parcour.id,
                  unity: this.subject.unity.id,
                  mini_project: this.subject.mini_project,
                  tp: this.subject.tp,
                  number_authorized_absences: this.subject.number_authorized_absences,
                  exam_mode: this.subject.exam_mode,
                  label_arabe: this.subject.label_arabe,
                  is_active: this.subject.is_active,
                },
              )
            this.$router.push('/subjects')
            setTimeout(() => {
              this.showToast('success', 'top-center', 'Matière ajouté avec succés')
            }, 1000)
          } catch (err) {
            setTimeout(() => {
              this.showToast('danger', 'top-center', err.toString())
            }, 1000)
          }
        }
      })
    },

    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },

  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
